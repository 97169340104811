import { Path } from 'path-parser';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { usePaymentsApiFetch } from '../hooks';
import { getCookie } from '../utils';

const AUTHENTICATION_TOKEN_COOKIE = 'auth';
const IS_IMPERSONATED_COOKIE = 'i-oidc';
const PLATFORM_ACCOUNT_COOKIE = 'platform-account-oidc';
const PLATFORM_USER_COOKIE = 'platform-user-oidc';
const RETURN_TO_STORAGE_KEY = 'return-to';

const Authentication: React.FC<Pick<RouteComponentProps, 'location'>> = ({ location }) => {
    const { code, returnTo = '' } = parse(location.search);
    const { 0: response, 2: getAuthToken } = usePaymentsApiFetch('/auth/login', {
        data: { code },
        defer: true,
        excludeAuthorizationHeader: true,
        method: 'post'
    });

    useEffect(() => {
        if (code) {
            getAuthToken();
        }
    }, [code, getAuthToken]);

    useEffect(() => {
        if (!code || (response && response.status === 401)) {
            const authorizationUrlQueryParams = {
                client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
                max_age: process.env.REACT_APP_OIDC_MAX_AGE,
                response_type: 'code',
                scope: 'studio openid',
                redirect_uri: `${process.env.REACT_APP_BASE_URL}/authenticate`
            };
            const authorizationUrlQuery = `?${Object.entries(authorizationUrlQueryParams)
                .map((entry) => entry.join('='))
                .join('&')}`;
            const authorizationUrl = `${process.env.REACT_APP_OIDC_URL}${authorizationUrlQuery}`;

            localStorage.setItem(RETURN_TO_STORAGE_KEY, `${returnTo}`);
            window.location.assign(authorizationUrl);
        }
    }, [code, response, returnTo]);

    useEffect(() => {
        if (response?.data?.authToken) {
            const { data } = response;
            const { authToken, isDelegated, platformAccountId, platformUserId } = data;
            const redirectTo = localStorage.getItem(RETURN_TO_STORAGE_KEY) || '/';
            let redirectPath = '/';

            try {
                ({ path: redirectPath } = Path.createPath(redirectTo));
            } catch {}

            localStorage.removeItem(RETURN_TO_STORAGE_KEY);

            document.cookie = `${AUTHENTICATION_TOKEN_COOKIE}=${authToken}; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            document.cookie = `${IS_IMPERSONATED_COOKIE}=${isDelegated}; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            document.cookie = `${PLATFORM_ACCOUNT_COOKIE}=${platformAccountId}; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            document.cookie = `${PLATFORM_USER_COOKIE}=${platformUserId}; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            window.location.replace(redirectPath);
        }
    }, [response]);

    return null;
};

interface IAuthenticationTokenHook {
    invalidateToken: () => void;
    isDelegated: boolean;
    token?: string;
    platformAccountId?: string;
    platformUserId?: string;
}

export function useAuthenticationToken(): IAuthenticationTokenHook {
    return {
        invalidateToken: () => {
            document.cookie = `${AUTHENTICATION_TOKEN_COOKIE}=; max-age=0; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            document.cookie = `${IS_IMPERSONATED_COOKIE}=; max-age=0; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            document.cookie = `${PLATFORM_ACCOUNT_COOKIE}=; max-age=0; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
            document.cookie = `${PLATFORM_USER_COOKIE}=$; max-age=0; path=/; samesite=strict; ${
                process.env.NODE_ENV === 'production' ? 'secure' : ''
            }`;
        },
        isDelegated: getCookie(IS_IMPERSONATED_COOKIE) === 'true',
        token: getCookie(AUTHENTICATION_TOKEN_COOKIE),
        platformAccountId: getCookie(PLATFORM_ACCOUNT_COOKIE),
        platformUserId: getCookie(PLATFORM_USER_COOKIE)
    };
}

export default Authentication;
